module.exports = [
  {
    position: 5,
    icon: {
      style: 'fas fa-gamepad',
      color: '#3c00c9',
    },
    color: '#3c00c9',
    name: 'Компьютерные игры',
    parametrazedName: 'komputernye-igry',
    children: [
      {
        name: 'игры',
        parametrazedName: 'ighry',
      },
      {
        name: 'киберпанк 2077',
        parametrazedName: 'kibierpank-2077',
      },
      {
        name: 'киберспорт',
        parametrazedName: 'kibiersport',
      },
      {
        name: 'майнкрафт',
        parametrazedName: 'mainkraft',
      },
      {
        name: 'among us',
        parametrazedName: 'among-us',
      },
      {
        name: 'angry birds',
        parametrazedName: 'angry-birds',
      },
      {
        name: 'battlefield',
        parametrazedName: 'battlefield',
      },
      {
        name: 'brawl stars',
        parametrazedName: 'brawl-stars',
      },
      {
        name: 'call of duty',
        parametrazedName: 'call-of-duty',
      },
      {
        name: 'counter-strike',
        parametrazedName: 'counter-strike-1',
      },
      {
        name: 'cs go',
        parametrazedName: 'cs-go',
      },
      {
        name: 'cyberpunk 2077',
        parametrazedName: 'cyberpunk-2077',
      },
      {
        name: 'dark souls',
        parametrazedName: 'dark-souls',
      },
      {
        name: 'destiny',
        parametrazedName: 'destiny',
      },
      {
        name: 'detroit',
        parametrazedName: 'detroit',
      },
      {
        name: 'diablo',
        parametrazedName: 'diablo',
      },
      {
        name: 'doom',
        parametrazedName: 'doom',
      },
      {
        name: 'dragon age',
        parametrazedName: 'dragon-age',
      },
      {
        name: 'fallout',
        parametrazedName: 'fallout',
      },
      {
        name: 'far cry',
        parametrazedName: 'far-cry',
      },
      {
        name: "five nights at freddy's",
        parametrazedName: 'five-nights-at-freddys',
      },
      {
        name: 'fortnite',
        parametrazedName: 'fortnite',
      },
      {
        name: 'gears of war',
        parametrazedName: 'gears-of-war',
      },
      {
        name: 'god of war',
        parametrazedName: 'god-of-war',
      },
      {
        name: 'grand theft auto',
        parametrazedName: 'grand-theft-auto',
      },
      {
        name: 'gta',
        parametrazedName: 'gta',
      },
      {
        name: 'half-life',
        parametrazedName: 'half-life',
      },
      {
        name: 'hearthstone',
        parametrazedName: 'hearthstone',
      },
      {
        name: 'heroes of might and magic',
        parametrazedName: 'heroes-of-might-and-magic',
      },
      {
        name: 'hitman',
        parametrazedName: 'hitman',
      },
      {
        name: 'league of legends',
        parametrazedName: 'league-of-legends',
      },
      {
        name: 'mario',
        parametrazedName: 'mario',
      },
      {
        name: 'mass effect',
        parametrazedName: 'mass-effect',
      },
      {
        name: 'metal gear',
        parametrazedName: 'metal-gear',
      },
      {
        name: 'metro exodus',
        parametrazedName: 'metro-exodus',
      },
      {
        name: 'minecraft',
        parametrazedName: 'minecraft',
      },
      {
        name: 'mortal kombat',
        parametrazedName: 'mortal-kombat',
      },
      {
        name: 'overwatch',
        parametrazedName: 'overwatch',
      },
      {
        name: 'pubg',
        parametrazedName: 'pubg',
      },
      {
        name: 'rainbow six',
        parametrazedName: 'rainbow-six',
      },
      {
        name: 'sally face',
        parametrazedName: 'sally-face',
      },
      {
        name: 'silent hill',
        parametrazedName: 'silent-hill',
      },
      {
        name: 'skyrim',
        parametrazedName: 'skyrim',
      },
      {
        name: 'stalker',
        parametrazedName: 'stalker',
      },
      {
        name: 'tanks',
        parametrazedName: 'tanks',
      },
      {
        name: 'the elder scrolls',
        parametrazedName: 'the-elder-scrolls',
      },
      {
        name: 'the last of us',
        parametrazedName: 'the-last-of-us',
      },
      {
        name: 'the witcher',
        parametrazedName: 'the-witcher',
      },
      {
        name: 'undertale',
        parametrazedName: 'undertale',
      },
      {
        name: 'warcraft',
        parametrazedName: 'warcraft',
      },
      {
        name: 'warhammer',
        parametrazedName: 'warhammer',
      },
      {
        name: 'wot',
        parametrazedName: 'wot',
      },
    ],
  },
  {
    position: 5,
    icon: {
      style: 'fas fa-music',
      color: '#c44cb8',
    },
    color: '#c44cb8',
    name: 'Музыка',
    parametrazedName: 'muzika',
    children: [
      {
        name: 'виктор цой',
        parametrazedName: 'viktor-tsoi',
      },
      {
        name: 'владимир высоцкий',
        parametrazedName: 'vladimir-vysotskii',
      },
      {
        name: 'гражданская оборона',
        parametrazedName: 'ghrazhdanskaia-oborona',
      },
      {
        name: 'гранж',
        parametrazedName: 'ghranzh',
      },
      {
        name: 'курт кобейн',
        parametrazedName: 'kurt-kobiein',
      },
      {
        name: 'ленинград',
        parametrazedName: 'lieninghrad',
      },
      {
        name: 'майкл джексон',
        parametrazedName: 'maikl-dzhiekson',
      },
      {
        name: 'метал',
        parametrazedName: 'mietal',
      },
      {
        name: 'музыка',
        parametrazedName: 'muzyka',
      },
      {
        name: 'нирвана',
        parametrazedName: 'nirvana-1',
      },
      {
        name: 'панк',
        parametrazedName: 'pank',
      },
      {
        name: 'рок',
        parametrazedName: 'rok',
      },
      {
        name: 'рок музыка',
        parametrazedName: 'rok-muzyka',
      },
      {
        name: 'рэп',
        parametrazedName: 'rep',
      },
      {
        name: 'шнур',
        parametrazedName: 'shnur',
      },
      {
        name: '2pac',
        parametrazedName: '2pac',
      },
      {
        name: '30 seconds to mars',
        parametrazedName: '30-seconds-to-mars',
      },
      {
        name: '50 cent',
        parametrazedName: '50-cent',
      },
      {
        name: 'aerosmith',
        parametrazedName: 'aerosmith',
      },
      {
        name: 'amy winehouse',
        parametrazedName: 'amy-winehouse',
      },
      {
        name: 'architects',
        parametrazedName: 'architects',
      },
      {
        name: 'arctic monkeys',
        parametrazedName: 'arctic-monkeys',
      },
      {
        name: 'armin van buuren',
        parametrazedName: 'armin-van-buuren',
      },
      {
        name: 'asking alexandria',
        parametrazedName: 'asking-alexandria',
      },
      {
        name: 'beyonce',
        parametrazedName: 'beyonce',
      },
      {
        name: 'billie eilish',
        parametrazedName: 'billie-eilish',
      },
      {
        name: 'black sabbath',
        parametrazedName: 'black-sabbath',
      },
      {
        name: 'blink-182',
        parametrazedName: 'blink-182',
      },
      {
        name: 'bring me the horizon',
        parametrazedName: 'bring-me-the-horizon',
      },
      {
        name: 'bts',
        parametrazedName: 'bts',
      },
      {
        name: 'bullet for my valentine',
        parametrazedName: 'bullet-for-my-valentine',
      },
      {
        name: 'coldplay',
        parametrazedName: 'coldplay',
      },
      {
        name: 'daft punk',
        parametrazedName: 'daft-punk',
      },
      {
        name: 'david bowie',
        parametrazedName: 'david-bowie',
      },
      {
        name: 'deep purple',
        parametrazedName: 'deep-purple',
      },
      {
        name: 'depeche mode',
        parametrazedName: 'depeche-mode',
      },
      {
        name: 'die antwoord',
        parametrazedName: 'die-antwoord',
      },
      {
        name: 'disturbed',
        parametrazedName: 'disturbed',
      },
      {
        name: 'eminem',
        parametrazedName: 'eminem',
      },
      {
        name: 'evanescence',
        parametrazedName: 'evanescence',
      },
      {
        name: 'fall out boy',
        parametrazedName: 'fall-out-boy',
      },
      {
        name: 'foo fighters',
        parametrazedName: 'foo-fighters',
      },
      {
        name: 'gorillaz',
        parametrazedName: 'gorillaz',
      },
      {
        name: 'green day',
        parametrazedName: 'green-day',
      },
      {
        name: 'guns n roses',
        parametrazedName: 'guns-n-roses',
      },
      {
        name: 'heavy metal',
        parametrazedName: 'heavy-metal',
      },
      {
        name: 'hollywood undead',
        parametrazedName: 'hollywood-undead',
      },
      {
        name: 'imagine dragons',
        parametrazedName: 'imagine-dragons',
      },
      {
        name: 'industrial',
        parametrazedName: 'industrial',
      },
      {
        name: 'iron maiden',
        parametrazedName: 'iron-maiden',
      },
      {
        name: 'jazz',
        parametrazedName: 'jazz',
      },
      {
        name: 'jimi hendrix',
        parametrazedName: 'jimi-hendrix',
      },
      {
        name: 'justin bieber',
        parametrazedName: 'justin-bieber',
      },
      {
        name: 'kanye west',
        parametrazedName: 'kanye-west',
      },
      {
        name: 'katy perry',
        parametrazedName: 'katy-perry',
      },
      {
        name: 'kendrick lamar',
        parametrazedName: 'kendrick-lamar',
      },
      {
        name: 'kiss',
        parametrazedName: 'kiss',
      },
      {
        name: 'korn',
        parametrazedName: 'korn',
      },
      {
        name: 'kurt cobain',
        parametrazedName: 'kurt-cobain',
      },
      {
        name: 'lady gaga',
        parametrazedName: 'lady-gaga',
      },
      {
        name: 'led zeppelin',
        parametrazedName: 'led-zeppelin',
      },
      {
        name: 'lil peep',
        parametrazedName: 'lil-peep',
      },
      {
        name: 'lil wayne',
        parametrazedName: 'lil-wayne',
      },
      {
        name: 'linkin park',
        parametrazedName: 'linkin-park',
      },
      {
        name: 'metallica',
        parametrazedName: 'metallica',
      },
      {
        name: 'motorhead',
        parametrazedName: 'motorhead',
      },
      {
        name: 'muse',
        parametrazedName: 'muse',
      },
      {
        name: 'music',
        parametrazedName: 'music',
      },
      {
        name: 'natus vincere',
        parametrazedName: 'natus-vincere',
      },
      {
        name: 'nickelback',
        parametrazedName: 'nickelback',
      },
      {
        name: 'nirvana',
        parametrazedName: 'nirvana',
      },
      {
        name: 'one direction',
        parametrazedName: 'one-direction',
      },
      {
        name: 'ozzy osbourne',
        parametrazedName: 'ozzy-osbourne',
      },
      {
        name: 'pink floyd',
        parametrazedName: 'pink-floyd',
      },
      {
        name: 'poets of the fall',
        parametrazedName: 'poets-of-the-fall',
      },
      {
        name: 'punk',
        parametrazedName: 'punk',
      },
      {
        name: 'queen',
        parametrazedName: 'queen',
      },
      {
        name: 'radiohead',
        parametrazedName: 'radiohead',
      },
      {
        name: 'rap',
        parametrazedName: 'rap',
      },
      {
        name: 'rihanna',
        parametrazedName: 'rihanna',
      },
      {
        name: 'rock',
        parametrazedName: 'rock',
      },
      {
        name: 'rolling stones',
        parametrazedName: 'rolling-stones',
      },
      {
        name: 'slayer',
        parametrazedName: 'slayer',
      },
      {
        name: 'slipknot',
        parametrazedName: 'slipknot',
      },
      {
        name: 'the beatles',
        parametrazedName: 'the-beatles',
      },
      {
        name: 'twenty one pilots',
        parametrazedName: 'twenty-one-pilots',
      },
    ],
  },
  {
    position: 6,
    icon: {
      style: 'fas fa-microphone',
      color: '#ff0178',
    },
    color: '#ff0178',
    name: 'bts',
    parametrazedName: 'bts',
    children: [],
  },
  {
    position: 6,
    icon: {
      style: 'fas fa-lightbulb',
      color: '#ff1e05',
    },
    color: '#ff1e05',
    name: 'Аниме/хентай',
    parametrazedName: 'printy-s-anime',
    children: [
      {
        name: 'аниме',
        parametrazedName: 'animie',
      },
      {
        name: 'атака титанов',
        parametrazedName: 'ataka-titanov',
      },
      {
        name: 'бездомный бог',
        parametrazedName: 'biezdomnyi-bogh',
      },
      {
        name: 'бесконечное лето',
        parametrazedName: 'bieskoniechnoie-lieto',
      },
      {
        name: 'бульбазавр',
        parametrazedName: 'bulbazavr',
      },
      {
        name: 'ванпанчмен',
        parametrazedName: 'vanpanchmien',
      },
      {
        name: 'демоны старшей школы',
        parametrazedName: 'diemony-starshiei-shkoly',
      },
      {
        name: 'евангелион',
        parametrazedName: 'ievanghielion',
      },
      {
        name: 'кен канеки',
        parametrazedName: 'kien-kanieki',
      },
      {
        name: 'коносуба',
        parametrazedName: 'konosuba-1',
      },
      {
        name: 'луффи',
        parametrazedName: 'luffi',
      },
      {
        name: 'мой сосед тоторо',
        parametrazedName: 'moi-sosied-totoro',
      },
      {
        name: 'мононоке',
        parametrazedName: 'mononokie',
      },
      {
        name: 'моя геройская академия',
        parametrazedName: 'moia-ghieroiskaia-akadiemiia',
      },
      {
        name: 'пикачу',
        parametrazedName: 'pikachu',
      },
      {
        name: 'покемон',
        parametrazedName: 'pokiemon',
      },
      {
        name: 'призрак в доспехах',
        parametrazedName: 'prizrak-v-dospiekhakh',
      },
      {
        name: 'сейлор мун',
        parametrazedName: 'sieilor-mun',
      },
      {
        name: 'синий экзорцист',
        parametrazedName: 'sinii-ekzortsist',
      },
      {
        name: 'стальной алхимик',
        parametrazedName: 'stalnoi-alkhimik',
      },
      {
        name: 'сэнпай',
        parametrazedName: 'senpai',
      },
      {
        name: 'токийский гуль',
        parametrazedName: 'tokiiskii-ghul',
      },
      {
        name: 'тоторо',
        parametrazedName: 'totoro',
      },
      {
        name: 'унесенные призраками',
        parametrazedName: 'uniesiennyie-prizrakami',
      },
      {
        name: 'хвост феи',
        parametrazedName: 'khvost-fiei',
      },
      {
        name: 'чермандер',
        parametrazedName: 'chiermandier',
      },
      {
        name: 'ahegao',
        parametrazedName: 'ahegao',
      },
      {
        name: 'danganronpa',
        parametrazedName: 'danganronpa',
      },
      {
        name: 'dragon ball',
        parametrazedName: 'dragon-ball-1',
      },
      {
        name: 'gintama',
        parametrazedName: 'gintama',
      },
      {
        name: 'high school dxd',
        parametrazedName: 'high-school-dxd',
      },
      {
        name: 'manga',
        parametrazedName: 'manga',
      },
      {
        name: 'no game no life',
        parametrazedName: 'no-game-no-life',
      },
      {
        name: 'one piece',
        parametrazedName: 'one-piece',
      },
      {
        name: 'overlord',
        parametrazedName: 'overlord',
      },
      {
        name: 'rwby',
        parametrazedName: 'rwby',
      },
      {
        name: 'senpai',
        parametrazedName: 'senpai-1',
      },
      {
        name: 'sword art online',
        parametrazedName: 'sword-art-online',
      },
    ],
  },
  {
    position: 7,
    color: '',
    name: 'Год рождения',
    parametrazedName: 'god-rogdeniya',
    children: [
      {
        name: '1960',
        parametrazedName: '1960',
      },
      {
        name: '1961',
        parametrazedName: '1961',
      },
      {
        name: '1962',
        parametrazedName: '1962',
      },
      {
        name: '1963',
        parametrazedName: '1963',
      },
      {
        name: '1964',
        parametrazedName: '1964',
      },
      {
        name: '1965',
        parametrazedName: '1965',
      },
      {
        name: '1966',
        parametrazedName: '1966',
      },
      {
        name: '1967',
        parametrazedName: '1967',
      },
      {
        name: '1968',
        parametrazedName: '1968',
      },
      {
        name: '1969',
        parametrazedName: '1969',
      },
      {
        name: '1970',
        parametrazedName: '1970',
      },
      {
        name: '1971',
        parametrazedName: '1971',
      },
      {
        name: '1972',
        parametrazedName: '1972',
      },
      {
        name: '1973',
        parametrazedName: '1973',
      },
      {
        name: '1974',
        parametrazedName: '1974',
      },
      {
        name: '1975',
        parametrazedName: '1975',
      },
      {
        name: '1976',
        parametrazedName: '1976',
      },
      {
        name: '1977',
        parametrazedName: '1977',
      },
      {
        name: '1978',
        parametrazedName: '1978',
      },
      {
        name: '1979',
        parametrazedName: '1979',
      },
      {
        name: '1980',
        parametrazedName: '1980',
      },
      {
        name: '1981',
        parametrazedName: '1981',
      },
      {
        name: '1982',
        parametrazedName: '1982',
      },
      {
        name: '1983',
        parametrazedName: '1983',
      },
      {
        name: '1984',
        parametrazedName: '1984',
      },
      {
        name: '1985',
        parametrazedName: '1985',
      },
      {
        name: '1986',
        parametrazedName: '1986',
      },
      {
        name: '1987',
        parametrazedName: '1987',
      },
      {
        name: '1988',
        parametrazedName: '1988',
      },
      {
        name: '1989',
        parametrazedName: '1989',
      },
      {
        name: '1990',
        parametrazedName: '1990',
      },
      {
        name: '1991',
        parametrazedName: '1991',
      },
      {
        name: '1992',
        parametrazedName: '1992',
      },
      {
        name: '1993',
        parametrazedName: '1993',
      },
      {
        name: '1994',
        parametrazedName: '1994',
      },
      {
        name: '1995',
        parametrazedName: '1995',
      },
      {
        name: '1996',
        parametrazedName: '1996',
      },
      {
        name: '1997',
        parametrazedName: '1997',
      },
      {
        name: '1998',
        parametrazedName: '1998',
      },
      {
        name: '1999',
        parametrazedName: '1999',
      },
      {
        name: '2000',
        parametrazedName: '2000',
      },
      {
        name: '2001',
        parametrazedName: '2001',
      },
      {
        name: '2002',
        parametrazedName: '2002',
      },
      {
        name: '2003',
        parametrazedName: '2003',
      },
      {
        name: '2004',
        parametrazedName: '2004',
      },
    ],
  },
  {
    position: 8,
    color: '',
    name: 'Надписи',
    parametrazedName: 'prikolnye_nadpisi',
    children: [
      {
        name: 'надписи',
        parametrazedName: 'nadpisi',
      },
      {
        name: 'надпись',
        parametrazedName: 'nadpis',
      },
      {
        name: 'прикольные фразы',
        parametrazedName: 'prikolnyie-frazy',
      },
    ],
  },
  {
    position: 9,
    color: '',
    name: 'Имена',
    parametrazedName: 'imnennaya-odejda',
    children: [
      {
        name: 'александр',
        parametrazedName: 'alieksandr',
      },
      {
        name: 'александра',
        parametrazedName: 'alieksandra',
      },
      {
        name: 'алексей',
        parametrazedName: 'alieksiei',
      },
      {
        name: 'алина',
        parametrazedName: 'alina',
      },
      {
        name: 'анастасия',
        parametrazedName: 'anastasiia',
      },
      {
        name: 'анатолий',
        parametrazedName: 'anatolii',
      },
      {
        name: 'андрей',
        parametrazedName: 'andriei',
      },
      {
        name: 'андрюха',
        parametrazedName: 'andriukha',
      },
      {
        name: 'анна',
        parametrazedName: 'anna-1',
      },
      {
        name: 'антон',
        parametrazedName: 'anton',
      },
      {
        name: 'аня',
        parametrazedName: 'ania',
      },
      {
        name: 'арсений',
        parametrazedName: 'arsienii',
      },
      {
        name: 'артур',
        parametrazedName: 'artur',
      },
      {
        name: 'вадим',
        parametrazedName: 'vadim',
      },
      {
        name: 'валентина',
        parametrazedName: 'valientina',
      },
      {
        name: 'валера',
        parametrazedName: 'valiera',
      },
      {
        name: 'валерий',
        parametrazedName: 'valierii',
      },
      {
        name: 'варвара',
        parametrazedName: 'varvara',
      },
      {
        name: 'василий',
        parametrazedName: 'vasilii',
      },
      {
        name: 'вика',
        parametrazedName: 'vika',
      },
      {
        name: 'виктория',
        parametrazedName: 'viktoriia',
      },
      {
        name: 'владимир',
        parametrazedName: 'vladimir',
      },
      {
        name: 'вова',
        parametrazedName: 'vova',
      },
      {
        name: 'вячеслав',
        parametrazedName: 'viachieslav',
      },
      {
        name: 'данила',
        parametrazedName: 'danila',
      },
      {
        name: 'денис',
        parametrazedName: 'dienis',
      },
      {
        name: 'дмитрий',
        parametrazedName: 'dmitrii',
      },
      {
        name: 'ева',
        parametrazedName: 'ieva',
      },
      {
        name: 'евгений',
        parametrazedName: 'ievghienii',
      },
      {
        name: 'евгения',
        parametrazedName: 'ievghieniia',
      },
      {
        name: 'екатерина',
        parametrazedName: 'iekatierina',
      },
      {
        name: 'елена',
        parametrazedName: 'ieliena',
      },
      {
        name: 'женя',
        parametrazedName: 'zhienia',
      },
      {
        name: 'захар',
        parametrazedName: 'zakhar',
      },
      {
        name: 'илья',
        parametrazedName: 'ilia',
      },
      {
        name: 'именные футболки',
        parametrazedName: 'imiennyie-futbolki',
      },
      {
        name: 'ирина',
        parametrazedName: 'irina-1',
      },
      {
        name: 'катя',
        parametrazedName: 'katia',
      },
      {
        name: 'кирилл',
        parametrazedName: 'kirill-1',
      },
      {
        name: 'коля',
        parametrazedName: 'kolia',
      },
      {
        name: 'ксения',
        parametrazedName: 'ksieniia',
      },
      {
        name: 'ксюша',
        parametrazedName: 'ksiusha',
      },
      {
        name: 'лена',
        parametrazedName: 'liena',
      },
      {
        name: 'макар',
        parametrazedName: 'makar',
      },
      {
        name: 'маргарита',
        parametrazedName: 'margharita',
      },
      {
        name: 'марина',
        parametrazedName: 'marina',
      },
      {
        name: 'мария',
        parametrazedName: 'mariia-1',
      },
      {
        name: 'милана',
        parametrazedName: 'milana',
      },
      {
        name: 'милена',
        parametrazedName: 'miliena',
      },
      {
        name: 'надежда',
        parametrazedName: 'nadiezhda',
      },
      {
        name: 'надя',
        parametrazedName: 'nadia',
      },
      {
        name: 'настя',
        parametrazedName: 'nastia',
      },
      {
        name: 'никита',
        parametrazedName: 'nikita',
      },
      {
        name: 'николай',
        parametrazedName: 'nikolai',
      },
      {
        name: 'нина',
        parametrazedName: 'nina',
      },
      {
        name: 'нинель',
        parametrazedName: 'niniel',
      },
      {
        name: 'олег',
        parametrazedName: 'oliegh',
      },
      {
        name: 'ольга',
        parametrazedName: 'olgha',
      },
      {
        name: 'оля',
        parametrazedName: 'olia',
      },
      {
        name: 'павел',
        parametrazedName: 'paviel',
      },
      {
        name: 'паша',
        parametrazedName: 'pasha',
      },
      {
        name: 'полина',
        parametrazedName: 'polina',
      },
      {
        name: 'руслан',
        parametrazedName: 'ruslan',
      },
      {
        name: 'саша',
        parametrazedName: 'sasha',
      },
      {
        name: 'света',
        parametrazedName: 'svieta',
      },
      {
        name: 'семен',
        parametrazedName: 'siemien',
      },
      {
        name: 'слава',
        parametrazedName: 'slava',
      },
      {
        name: 'соня',
        parametrazedName: 'sonia',
      },
      {
        name: 'станислав',
        parametrazedName: 'stanislav',
      },
      {
        name: 'татьяна',
        parametrazedName: 'tatiana-1',
      },
      {
        name: 'тимофей',
        parametrazedName: 'timofiei',
      },
      {
        name: 'яна',
        parametrazedName: 'iana',
      },
    ],
  },
  {
    position: 10,
    color: '',
    name: 'Кино',
    parametrazedName: 'printy-iz-kino',
    children: [
      {
        name: 'безумный макс',
        parametrazedName: 'biezumnyi-maks',
      },
      {
        name: 'богемская рапсодия',
        parametrazedName: 'boghiemskaia-rapsodiia',
      },
      {
        name: 'бойцовский клуб',
        parametrazedName: 'boitsovskii-klub',
      },
      {
        name: 'большой лебовски',
        parametrazedName: 'bolshoi-liebovski',
      },
      {
        name: 'властелин колец',
        parametrazedName: 'vlastielin-koliets',
      },
      {
        name: 'джеймс бонд',
        parametrazedName: 'dzhieims-bond',
      },
      {
        name: 'звездный путь',
        parametrazedName: 'zviezdnyi-put',
      },
      {
        name: 'кино',
        parametrazedName: 'kino-1',
      },
      {
        name: 'криминальное чтиво',
        parametrazedName: 'kriminalnoie-chtivo',
      },
      {
        name: 'ла-ла ленд',
        parametrazedName: 'la-la-liend',
      },
      {
        name: 'леон',
        parametrazedName: 'lieon',
      },
      {
        name: 'обитель зла',
        parametrazedName: 'obitiel-zla',
      },
      {
        name: 'оно',
        parametrazedName: 'ono',
      },
      {
        name: 'пятница 13',
        parametrazedName: 'piatnitsa-13',
      },
      {
        name: 'рокки',
        parametrazedName: 'rokki',
      },
      {
        name: 'сумерки',
        parametrazedName: 'sumierki',
      },
      {
        name: 'тарантино',
        parametrazedName: 'tarantino-1',
      },
      {
        name: 'терминатор',
        parametrazedName: 'tierminator',
      },
      {
        name: 'титаник',
        parametrazedName: 'titanik',
      },
      {
        name: 'трансформеры',
        parametrazedName: 'transformiery',
      },
      {
        name: 'ужасы',
        parametrazedName: 'uzhasy',
      },
      {
        name: 'фантастика',
        parametrazedName: 'fantastika',
      },
      {
        name: 'фильм',
        parametrazedName: 'film',
      },
      {
        name: 'фредди крюгер',
        parametrazedName: 'frieddi-kriughier',
      },
      {
        name: 'чужой',
        parametrazedName: 'chuzhoi',
      },
      {
        name: 'call me by your name',
        parametrazedName: 'call-me-by-your-name',
      },
      {
        name: 'predator',
        parametrazedName: 'predator',
      },
      {
        name: 'silent hill',
        parametrazedName: 'silent-hill',
      },
    ],
  },
  {
    position: 10,
    icon: {
      style: 'fas fa-skull',
      color: '#00c24e',
    },
    color: '#00c24e',
    name: 'brawl stars',
    parametrazedName: 'brawl-stars',
    children: [],
  },
  {
    position: 12,
    color: '',
    name: 'Мультфильмы',
    parametrazedName: 'printy-iz-multikov',
    children: [
      {
        name: 'анимэ',
        parametrazedName: 'anime',
      },
      {
        name: 'атака титанов',
        parametrazedName: 'ataka-titanov',
      },
      {
        name: 'боджек',
        parametrazedName: 'bodzhiek',
      },
      {
        name: 'гадкий я',
        parametrazedName: 'ghadkii-ia',
      },
      {
        name: 'мультфильм',
        parametrazedName: 'multfilm',
      },
      {
        name: 'мультфильмы',
        parametrazedName: 'multfilmy',
      },
      {
        name: 'покемоны',
        parametrazedName: 'pokiemony',
      },
      {
        name: 'пятачок',
        parametrazedName: 'piatachok',
      },
      {
        name: 'шрек',
        parametrazedName: 'shriek',
      },
      {
        name: 'frozen',
        parametrazedName: 'frozen',
      },
    ],
  },
  {
    position: 13,
    color: '',
    name: 'Искусство',
    parametrazedName: 'iskusstvo-kartini',
    children: [
      {
        name: 'абстракционизм',
        parametrazedName: 'abstraktsionizm',
      },
      {
        name: 'босх',
        parametrazedName: 'boskh',
      },
      {
        name: 'ван гог',
        parametrazedName: 'van-ghogh',
      },
      {
        name: 'дали',
        parametrazedName: 'dali',
      },
      {
        name: 'живопись',
        parametrazedName: 'zhivopis',
      },
      {
        name: 'искусство',
        parametrazedName: 'iskusstvo',
      },
      {
        name: 'кандинский',
        parametrazedName: 'kandinskii',
      },
      {
        name: 'картина',
        parametrazedName: 'kartina',
      },
      {
        name: 'пейзаж',
        parametrazedName: 'pieizazh',
      },
      {
        name: 'пикассо',
        parametrazedName: 'pikasso',
      },
      {
        name: 'поль гоген',
        parametrazedName: 'pol-ghoghien',
      },
      {
        name: 'прерафаэлиты',
        parametrazedName: 'prierafaelity',
      },
    ],
  },
  {
    position: 14,
    color: '',
    name: 'Сериалы',
    parametrazedName: 'serialy',
    children: [
      {
        name: 'американская история ужасов',
        parametrazedName: 'amierikanskaia-istoriia-uzhasov',
      },
      {
        name: 'викинги',
        parametrazedName: 'vikinghi',
      },
      {
        name: 'волчонок',
        parametrazedName: 'volchonok',
      },
      {
        name: 'доктор кто',
        parametrazedName: 'doktor-kto',
      },
      {
        name: 'игра в кальмара',
        parametrazedName: 'ighra-v-kalmara',
      },
      {
        name: 'игра престолов',
        parametrazedName: 'ighra-priestolov-2',
      },
      {
        name: 'карточный домик',
        parametrazedName: 'kartochnyi-domik',
      },
      {
        name: 'король и шут',
        parametrazedName: 'korol-i-shut',
      },
      {
        name: 'очень странные дела',
        parametrazedName: 'ochien-strannyie-diela',
      },
      {
        name: 'ривердейл',
        parametrazedName: 'rivierdieil',
      },
      {
        name: 'сверхъестественное',
        parametrazedName: 'svierkhiestiestviennoie',
      },
      {
        name: 'секретные материалы',
        parametrazedName: 'siekrietnyie-matierialy',
      },
      {
        name: 'сериал',
        parametrazedName: 'sierial',
      },
      {
        name: 'сыны анархии',
        parametrazedName: 'syny-anarkhii',
      },
      {
        name: 'твин пикс',
        parametrazedName: 'tvin-piks',
      },
      {
        name: 'ходячие мертвецы',
        parametrazedName: 'khodiachiie-miertvietsy',
      },
      {
        name: 'черные паруса',
        parametrazedName: 'chiernyie-parusa',
      },
      {
        name: 'шелдон купер',
        parametrazedName: 'shieldon-kupier',
      },
      {
        name: 'шерлок холмс',
        parametrazedName: 'shierlok-kholms',
      },
      {
        name: 'friends',
        parametrazedName: 'friends',
      },
      {
        name: 'sons of anarchy',
        parametrazedName: 'sons-of-anarchy',
      },
      {
        name: 'supernatural',
        parametrazedName: 'supernatural',
      },
      {
        name: 'teen wolf',
        parametrazedName: 'teen-wolf',
      },
      {
        name: 'the big bang theory',
        parametrazedName: 'the-big-bang-theory',
      },
      {
        name: 'the walking dead',
        parametrazedName: 'the-walking-dead',
      },
      {
        name: 'true detective',
        parametrazedName: 'true-detective',
      },
      {
        name: 'twin peaks',
        parametrazedName: 'twin-peaks',
      },
    ],
  },
  {
    position: 17,
    color: '#030300',
    name: 'Комиксы',
    parametrazedName: 'printy-po-komiksam',
    children: [
      {
        name: 'вендетта',
        parametrazedName: 'viendietta',
      },
      {
        name: 'джокер',
        parametrazedName: 'dzhokier',
      },
      {
        name: 'зеленый фонарь',
        parametrazedName: 'zielienyi-fonar',
      },
      {
        name: 'комиксы',
        parametrazedName: 'komiksy',
      },
      {
        name: 'лига справедливости',
        parametrazedName: 'ligha-spraviedlivosti',
      },
      {
        name: 'мой сосед тоторо',
        parametrazedName: 'moi-sosied-totoro',
      },
      {
        name: 'супергерой',
        parametrazedName: 'supierghieroi-1',
      },
      {
        name: 'флэш',
        parametrazedName: 'flesh',
      },
      {
        name: 'чудо-женщина',
        parametrazedName: 'chudo-zhienshchina',
      },
      {
        name: 'batman',
        parametrazedName: 'batman',
      },
      {
        name: 'comics',
        parametrazedName: 'comics',
      },
      {
        name: 'dc comics',
        parametrazedName: 'dc-comics',
      },
      {
        name: 'flash',
        parametrazedName: 'flash',
      },
      {
        name: 'the punisher',
        parametrazedName: 'the-punisher',
      },
    ],
  },
  {
    position: 18,
    icon: {
      style: '\u003ci class="fas fa-spa"\u003e\u003c/i\u003e',
      color: '#c20733',
    },
    color: '#c20733',
    name: 'Цветы',
    parametrazedName: 'vse-cvety',
    children: [
      {
        name: 'ирисы',
        parametrazedName: 'irisy',
      },
      {
        name: 'кактус',
        parametrazedName: 'kaktus',
      },
      {
        name: 'колокольчик',
        parametrazedName: 'kolokolchik',
      },
      {
        name: 'ландыши',
        parametrazedName: 'landyshi',
      },
      {
        name: 'лилии',
        parametrazedName: 'lilii',
      },
      {
        name: 'магнолия',
        parametrazedName: 'maghnoliia',
      },
      {
        name: 'маки',
        parametrazedName: 'maki',
      },
      {
        name: 'незабудки',
        parametrazedName: 'niezabudki',
      },
      {
        name: 'одуванчики',
        parametrazedName: 'oduvanchiki',
      },
      {
        name: 'орхидеи',
        parametrazedName: 'orkhidiei',
      },
      {
        name: 'подснежники',
        parametrazedName: 'podsniezhniki',
      },
      {
        name: 'подсолнухи',
        parametrazedName: 'podsolnukhi',
      },
      {
        name: 'роза',
        parametrazedName: 'roza',
      },
      {
        name: 'розы',
        parametrazedName: 'rozy',
      },
      {
        name: 'ромашки',
        parametrazedName: 'romashki',
      },
      {
        name: 'тюльпаны',
        parametrazedName: 'tiulpany',
      },
      {
        name: 'фиалки',
        parametrazedName: 'fialki',
      },
      {
        name: 'цветок',
        parametrazedName: 'tsvietok',
      },
      {
        name: 'цветы',
        parametrazedName: 'tsviety',
      },
    ],
  },
  {
    position: 25,
    color: null,
    name: 'Звёздные войны',
    parametrazedName: 'printy-zvezdnye-voiny',
    children: [],
  },
  {
    position: 26,
    icon: {
      style: 'fas fa-rocket',
      color: '#6c55bf',
    },
    color: '#6c55bf',
    name: 'Космос',
    parametrazedName: 'printy-kosmos',
    children: [
      {
        name: 'вселенная',
        parametrazedName: 'vsieliennaia',
      },
      {
        name: 'галактика',
        parametrazedName: 'ghalaktika',
      },
      {
        name: 'звезда',
        parametrazedName: 'zviezda',
      },
      {
        name: 'звезды',
        parametrazedName: 'zviezdy',
      },
      {
        name: 'земля',
        parametrazedName: 'ziemlia',
      },
      {
        name: 'инопланетянин',
        parametrazedName: 'inoplanietianin',
      },
      {
        name: 'космонавт',
        parametrazedName: 'kosmonavt',
      },
      {
        name: 'космос',
        parametrazedName: 'kosmos',
      },
      {
        name: 'луна',
        parametrazedName: 'luna',
      },
      {
        name: 'планета',
        parametrazedName: 'planieta',
      },
    ],
  },
  {
    position: 30,
    icon: {
      style: 'fas fa-female',
      color: '#ff05ee',
    },
    color: '#ff05ee',
    name: 'Девичник',
    parametrazedName: 'printy-na-devichnik',
    children: [
      {
        name: 'девичник',
        parametrazedName: 'dievichnik',
      },
      {
        name: 'жена',
        parametrazedName: 'zhiena',
      },
      {
        name: 'замуж',
        parametrazedName: 'zamuzh',
      },
      {
        name: 'невеста',
        parametrazedName: 'nieviesta',
      },
    ],
  },
  {
    position: 30,
    color: '',
    name: 'Юмор и приколы',
    parametrazedName: 'prikolnye-printy',
    children: [
      {
        name: 'выделись из толпы',
        parametrazedName: 'vydielis-iz-tolpy',
      },
      {
        name: 'дебилы',
        parametrazedName: 'diebily',
      },
      {
        name: 'креативно',
        parametrazedName: 'krieativno',
      },
      {
        name: 'ленивец',
        parametrazedName: 'lieniviets',
      },
      {
        name: 'лень',
        parametrazedName: 'lien-1',
      },
      {
        name: 'мем',
        parametrazedName: 'miem',
      },
      {
        name: 'нераскрывшееся олимпийское кольцо',
        parametrazedName: 'nieraskryvshieiesia-olimpiiskoie-koltso',
      },
      {
        name: 'олдскул',
        parametrazedName: 'oldskul',
      },
      {
        name: 'олимпийские кольца',
        parametrazedName: 'olimpiiskiie-koltsa',
      },
      {
        name: 'пародия',
        parametrazedName: 'parodiia',
      },
      {
        name: 'прикол',
        parametrazedName: 'prikol',
      },
      {
        name: 'приколы',
        parametrazedName: 'prikoly',
      },
      {
        name: 'прикольно',
        parametrazedName: 'prikolno',
      },
      {
        name: 'прикольные',
        parametrazedName: 'prikolnyie',
      },
      {
        name: 'путин приколы',
        parametrazedName: 'putin-prikoly',
      },
      {
        name: 'с надписями',
        parametrazedName: 's-nadpisiami',
      },
      {
        name: 'смешно',
        parametrazedName: 'smieshno',
      },
      {
        name: 'смешное',
        parametrazedName: 'smieshnoie',
      },
      {
        name: 'смешные',
        parametrazedName: 'smieshnyie',
      },
      {
        name: 'царь',
        parametrazedName: 'tsar',
      },
      {
        name: 'юмор',
        parametrazedName: 'iumor',
      },
      {
        name: 'fun',
        parametrazedName: 'fun',
      },
      {
        name: 'keep calm',
        parametrazedName: 'keep-calm',
      },
    ],
  },
  {
    position: 31,
    icon: {
      style: 'fas fa-user-tie',
      color: '#0b71ff',
    },
    color: '#0b71ff',
    name: 'Мальчишник',
    parametrazedName: 'printy-na-malchishnik',
    children: [
      {
        name: 'жених',
        parametrazedName: 'zhienikh',
      },
      {
        name: 'мальчишник',
        parametrazedName: 'malchishnik',
      },
      {
        name: 'муж',
        parametrazedName: 'muzh',
      },
    ],
  },
  {
    position: 32,
    color: '',
    name: 'Животные',
    parametrazedName: 'printy-s-zhivotnymi',
    children: [
      {
        name: 'бабочка',
        parametrazedName: 'babochka',
      },
      {
        name: 'бабочки',
        parametrazedName: 'babochki',
      },
      {
        name: 'бульдог',
        parametrazedName: 'buldogh',
      },
      {
        name: 'вельшкорги',
        parametrazedName: 'vielshkorghi',
      },
      {
        name: 'волк',
        parametrazedName: 'volk',
      },
      {
        name: 'динозавры',
        parametrazedName: 'dinozavry',
      },
      {
        name: 'доберман',
        parametrazedName: 'dobierman',
      },
      {
        name: 'единорог',
        parametrazedName: 'iedinorogh',
      },
      {
        name: 'енот',
        parametrazedName: 'ienot',
      },
      {
        name: 'животные',
        parametrazedName: 'zhivotnyie',
      },
      {
        name: 'заяц',
        parametrazedName: 'zaiats',
      },
      {
        name: 'кит',
        parametrazedName: 'kit',
      },
      {
        name: 'конь',
        parametrazedName: 'kon',
      },
      {
        name: 'корги',
        parametrazedName: 'korghi',
      },
      {
        name: 'кот',
        parametrazedName: 'kot',
      },
      {
        name: 'котенок',
        parametrazedName: 'kotienok',
      },
      {
        name: 'котик',
        parametrazedName: 'kotik',
      },
      {
        name: 'коты',
        parametrazedName: 'koty',
      },
      {
        name: 'котэ',
        parametrazedName: 'kote',
      },
      {
        name: 'кошка',
        parametrazedName: 'koshka',
      },
      {
        name: 'кролик',
        parametrazedName: 'krolik',
      },
      {
        name: 'лев',
        parametrazedName: 'liev',
      },
      {
        name: 'лис',
        parametrazedName: 'lis',
      },
      {
        name: 'лиса',
        parametrazedName: 'lisa',
      },
      {
        name: 'лошади',
        parametrazedName: 'loshadi',
      },
      {
        name: 'лошадь',
        parametrazedName: 'loshad',
      },
      {
        name: 'медведи',
        parametrazedName: 'miedviedi',
      },
      {
        name: 'медведь',
        parametrazedName: 'miedvied',
      },
      {
        name: 'мимими',
        parametrazedName: 'mimimi',
      },
      {
        name: 'мишка',
        parametrazedName: 'mishka',
      },
      {
        name: 'мопс',
        parametrazedName: 'mops',
      },
      {
        name: 'обезьяна',
        parametrazedName: 'obieziana',
      },
      {
        name: 'овчарка',
        parametrazedName: 'ovcharka',
      },
      {
        name: 'олень',
        parametrazedName: 'olien',
      },
      {
        name: 'панда',
        parametrazedName: 'panda',
      },
      {
        name: 'пес',
        parametrazedName: 'pies',
      },
      {
        name: 'пингвин',
        parametrazedName: 'pinghvin',
      },
      {
        name: 'попугай',
        parametrazedName: 'popughai',
      },
      {
        name: 'птица',
        parametrazedName: 'ptitsa',
      },
      {
        name: 'птицы',
        parametrazedName: 'ptitsy',
      },
      {
        name: 'рога',
        parametrazedName: 'rogha',
      },
      {
        name: 'рыба',
        parametrazedName: 'ryba',
      },
      {
        name: 'сердитый котик',
        parametrazedName: 'sierdityi-kotik',
      },
      {
        name: 'собака',
        parametrazedName: 'sobaka',
      },
      {
        name: 'собаки',
        parametrazedName: 'sobaki',
      },
      {
        name: 'сова',
        parametrazedName: 'sova',
      },
      {
        name: 'такса',
        parametrazedName: 'taksa',
      },
      {
        name: 'филин',
        parametrazedName: 'filin',
      },
      {
        name: 'французский бульдог',
        parametrazedName: 'frantsuzskii-buldogh',
      },
      {
        name: 'хаски',
        parametrazedName: 'khaski',
      },
      {
        name: 'хищник',
        parametrazedName: 'khishchnik',
      },
      {
        name: 'царь зверей',
        parametrazedName: 'tsar-zvieriei',
      },
      {
        name: 'шимпанзе',
        parametrazedName: 'shimpanzie',
      },
      {
        name: 'animal',
        parametrazedName: 'animal',
      },
      {
        name: 'bear',
        parametrazedName: 'bear',
      },
      {
        name: 'cat',
        parametrazedName: 'cat',
      },
      {
        name: 'deer',
        parametrazedName: 'deer',
      },
      {
        name: 'dog',
        parametrazedName: 'dog',
      },
      {
        name: 'doge',
        parametrazedName: 'doge',
      },
      {
        name: 'fox',
        parametrazedName: 'fox',
      },
      {
        name: 'horse',
        parametrazedName: 'horse',
      },
      {
        name: 'lion',
        parametrazedName: 'lion',
      },
      {
        name: 'monkey',
        parametrazedName: 'monkey',
      },
      {
        name: 'owl',
        parametrazedName: 'owl',
      },
      {
        name: 'panda',
        parametrazedName: 'panda-1',
      },
      {
        name: 'pug',
        parametrazedName: 'pug',
      },
      {
        name: 'swag',
        parametrazedName: 'swag',
      },
      {
        name: 'wolf',
        parametrazedName: 'wolf',
      },
      {
        name: 'ёжик',
        parametrazedName: 'iozhik',
      },
    ],
  },
  {
    position: 34,
    color: '',
    name: 'Природа',
    parametrazedName: 'printy-s-prirodoy',
    children: [
      {
        name: 'весна',
        parametrazedName: 'viesna',
      },
      {
        name: 'вода',
        parametrazedName: 'voda',
      },
      {
        name: 'горы',
        parametrazedName: 'ghory',
      },
      {
        name: 'закат',
        parametrazedName: 'zakat',
      },
      {
        name: 'земля',
        parametrazedName: 'ziemlia',
      },
      {
        name: 'зима',
        parametrazedName: 'zima',
      },
      {
        name: 'красиво',
        parametrazedName: 'krasivo',
      },
      {
        name: 'лес',
        parametrazedName: 'lies',
      },
      {
        name: 'лето',
        parametrazedName: 'lieto',
      },
      {
        name: 'луна',
        parametrazedName: 'luna',
      },
      {
        name: 'маки',
        parametrazedName: 'maki',
      },
      {
        name: 'море',
        parametrazedName: 'morie',
      },
      {
        name: 'небо',
        parametrazedName: 'niebo',
      },
      {
        name: 'океан',
        parametrazedName: 'okiean',
      },
      {
        name: 'осень',
        parametrazedName: 'osien',
      },
      {
        name: 'пейзаж',
        parametrazedName: 'pieizazh',
      },
      {
        name: 'пляж',
        parametrazedName: 'pliazh',
      },
      {
        name: 'природа',
        parametrazedName: 'priroda',
      },
      {
        name: 'роза',
        parametrazedName: 'roza',
      },
      {
        name: 'розы',
        parametrazedName: 'rozy',
      },
      {
        name: 'сад',
        parametrazedName: 'sad',
      },
      {
        name: 'сакура',
        parametrazedName: 'sakura',
      },
      {
        name: 'север',
        parametrazedName: 'sievier',
      },
      {
        name: 'солнце',
        parametrazedName: 'solntsie',
      },
      {
        name: 'цветочки',
        parametrazedName: 'tsvietochki',
      },
      {
        name: 'цветы',
        parametrazedName: 'tsviety',
      },
    ],
  },
  {
    position: 38,
    color: '',
    name: 'Спорт',
    parametrazedName: 'sportivnye-printy',
    children: [
      {
        name: 'американский футбол',
        parametrazedName: 'amierikanskii-futbol',
      },
      {
        name: 'баскетбол',
        parametrazedName: 'baskietbol',
      },
      {
        name: 'бег',
        parametrazedName: 'biegh',
      },
      {
        name: 'бодибилдинг',
        parametrazedName: 'bodibildingh',
      },
      {
        name: 'бокс',
        parametrazedName: 'boks',
      },
      {
        name: 'боксер',
        parametrazedName: 'boksier',
      },
      {
        name: 'брюс ли',
        parametrazedName: 'brius-li',
      },
      {
        name: 'велосипед',
        parametrazedName: 'vielosipied',
      },
      {
        name: 'гантели',
        parametrazedName: 'ghantieli',
      },
      {
        name: 'карате',
        parametrazedName: 'karatie',
      },
      {
        name: 'качалка',
        parametrazedName: 'kachalka',
      },
      {
        name: 'конор макгрегор',
        parametrazedName: 'konor-makghrieghor',
      },
      {
        name: 'кроссфит',
        parametrazedName: 'krossfit',
      },
      {
        name: 'майк тайсон',
        parametrazedName: 'maik-taison',
      },
      {
        name: 'муай тай',
        parametrazedName: 'muai-tai',
      },
      {
        name: 'нба',
        parametrazedName: 'nba-1',
      },
      {
        name: 'нхл',
        parametrazedName: 'nkhl',
      },
      {
        name: 'овечкин',
        parametrazedName: 'oviechkin',
      },
      {
        name: 'олимпиада',
        parametrazedName: 'olimpiada',
      },
      {
        name: 'олимпийские игры',
        parametrazedName: 'olimpiiskiie-ighry',
      },
      {
        name: 'пауэрлифтинг',
        parametrazedName: 'pauerliftingh',
      },
      {
        name: 'регби',
        parametrazedName: 'rieghbi',
      },
      {
        name: 'рестлинг',
        parametrazedName: 'riestlingh',
      },
      {
        name: 'скейтборд',
        parametrazedName: 'skieitbord',
      },
      {
        name: 'сноуборд',
        parametrazedName: 'snoubord',
      },
      {
        name: 'спорт',
        parametrazedName: 'sport',
      },
      {
        name: 'фитнес',
        parametrazedName: 'fitnies',
      },
      {
        name: 'хоккей',
        parametrazedName: 'khokkiei',
      },
      {
        name: 'штанга',
        parametrazedName: 'shtangha',
      },
      {
        name: 'all blacks',
        parametrazedName: 'all-blacks',
      },
      {
        name: 'diving',
        parametrazedName: 'diving',
      },
      {
        name: 'gym',
        parametrazedName: 'gym',
      },
      {
        name: 'hockey',
        parametrazedName: 'hockey',
      },
      {
        name: 'karate',
        parametrazedName: 'karate',
      },
      {
        name: 'mma',
        parametrazedName: 'mma',
      },
      {
        name: 'nba',
        parametrazedName: 'nba',
      },
      {
        name: 'nhl',
        parametrazedName: 'nhl',
      },
      {
        name: 'snowboard',
        parametrazedName: 'snowboard',
      },
      {
        name: 'ufc',
        parametrazedName: 'ufc',
      },
      {
        name: 'yoga',
        parametrazedName: 'yoga',
      },
    ],
  },
  {
    position: 40,
    color: '',
    name: 'СССР',
    parametrazedName: 'printy-sssr',
    children: [
      {
        name: 'советский союз',
        parametrazedName: 'sovietskii-soiuz',
      },
      {
        name: 'ссср',
        parametrazedName: 'sssr',
      },
      {
        name: 'soviet union',
        parametrazedName: 'soviet-union',
      },
      {
        name: 'ussr',
        parametrazedName: 'ussr',
      },
    ],
  },
  {
    position: 42,
    color: '',
    name: 'Россия',
    parametrazedName: 'printy-rossia',
    children: [
      {
        name: 'патриот',
        parametrazedName: 'patriot',
      },
      {
        name: 'раша',
        parametrazedName: 'rasha',
      },
      {
        name: 'родина',
        parametrazedName: 'rodina',
      },
      {
        name: 'российский флаг',
        parametrazedName: 'rossiiskii-flagh',
      },
      {
        name: 'россия',
        parametrazedName: 'rossiia',
      },
      {
        name: 'русский',
        parametrazedName: 'russkii',
      },
      {
        name: 'русь',
        parametrazedName: 'rus',
      },
      {
        name: 'триколор',
        parametrazedName: 'trikolor',
      },
      {
        name: 'я русский',
        parametrazedName: 'ia-russkii',
      },
      {
        name: 'russia',
        parametrazedName: 'russia',
      },
    ],
  },
  {
    position: 46,
    color: '',
    name: 'Путин',
    parametrazedName: 'printy-s-putinym',
    children: [
      {
        name: 'путин',
        parametrazedName: 'putin',
      },
      {
        name: 'putin',
        parametrazedName: 'putin-1',
      },
    ],
  },
  {
    position: 47,
    icon: {
      style: 'fas fa-space-shuttle',
      color: '#2a12b3',
    },
    color: '#2a12b3',
    name: 'among us',
    parametrazedName: 'among-us',
    children: [],
  },
  {
    position: 48,
    color: '',
    name: 'Гравити Фолз',
    parametrazedName: 'graviti-falls',
    children: [
      {
        name: 'билл шифр',
        parametrazedName: 'bill-shifr',
      },
      {
        name: 'графити фолз',
        parametrazedName: 'ghrafiti-folz',
      },
      {
        name: 'диппер',
        parametrazedName: 'dippier',
      },
      {
        name: 'мэйбл',
        parametrazedName: 'meibl',
      },
      {
        name: 'мэйбл пайнс',
        parametrazedName: 'meibl-pains',
      },
    ],
  },
  {
    position: 49,
    icon: {
      style: 'far fa-square',
      color: '#258a6d',
    },
    color: '#258a6d',
    name: 'игра в кальмара',
    parametrazedName: 'ighra-v-kalmara',
    children: [],
  },
  {
    position: 55,
    icon: {
      style: 'fas fa-gamepad',
      color: '#158aff',
    },
    color: '#158aff',
    name: 'Sally Face',
    parametrazedName: 'sally-face-game',
    children: [
      {
        name: 'салли фейс',
        parametrazedName: 'salli-fieis',
      },
      {
        name: 'sally face',
        parametrazedName: 'sally-face',
      },
    ],
  },
  {
    position: 55,
    color: '',
    name: 'Силовые структуры',
    parametrazedName: 'printy-silovye-struktury',
    children: [
      {
        name: 'армия',
        parametrazedName: 'armiia',
      },
      {
        name: 'армия россии',
        parametrazedName: 'armiia-rossii',
      },
      {
        name: 'вдв',
        parametrazedName: 'vdv',
      },
      {
        name: 'вежливые люди',
        parametrazedName: 'viezhlivyie-liudi',
      },
      {
        name: 'военный',
        parametrazedName: 'voiennyi',
      },
      {
        name: 'голубой берет',
        parametrazedName: 'gholuboi-bieriet',
      },
      {
        name: 'десант',
        parametrazedName: 'diesant',
      },
      {
        name: 'мвд',
        parametrazedName: 'mvd',
      },
      {
        name: 'милитари',
        parametrazedName: 'militari',
      },
      {
        name: 'пограничные войска',
        parametrazedName: 'poghranichnyie-voiska',
      },
    ],
  },
  {
    position: 58,
    color: '#000000',
    name: 'Цвета',
    parametrazedName: 'cveta',
    children: [
      {
        name: 'бежевый',
        parametrazedName: 'bezhevyi',
      },
      {
        name: 'белый',
        parametrazedName: 'belyi',
      },
      {
        name: 'бордовый',
        parametrazedName: 'bordovyi',
      },
      {
        name: 'голубой',
        parametrazedName: 'goluboi',
      },
      {
        name: 'желтый',
        parametrazedName: 'zheltyi',
      },
      {
        name: 'зеленый',
        parametrazedName: 'zelenyi',
      },
      {
        name: 'красный',
        parametrazedName: 'krasnyi',
      },
      {
        name: 'оранжевый',
        parametrazedName: 'oranzhevyi',
      },
      {
        name: 'серый',
        parametrazedName: 'seryi',
      },
      {
        name: 'темно-синий',
        parametrazedName: 'temno-sinii',
      },
      {
        name: 'черный',
        parametrazedName: 'chernyi',
      },
      {
        name: 'ярко-синий',
        parametrazedName: 'iarko-siniy',
      },
    ],
  },
  {
    position: 60,
    color: '',
    name: 'Авто и мото',
    parametrazedName: 'avto-i-moto',
    children: [
      {
        name: 'авто',
        parametrazedName: 'avto',
      },
      {
        name: 'автомобили',
        parametrazedName: 'avtomobili',
      },
      {
        name: 'ауди',
        parametrazedName: 'audi',
      },
      {
        name: 'байк',
        parametrazedName: 'baik',
      },
      {
        name: 'байкер',
        parametrazedName: 'baikier',
      },
      {
        name: 'дрифт',
        parametrazedName: 'drift-1',
      },
      {
        name: 'лада',
        parametrazedName: 'lada',
      },
      {
        name: 'машина',
        parametrazedName: 'mashina',
      },
      {
        name: 'мото',
        parametrazedName: 'moto',
      },
      {
        name: 'мотоцикл',
        parametrazedName: 'mototsikl',
      },
      {
        name: 'скорость',
        parametrazedName: 'skorost',
      },
      {
        name: 'тачки',
        parametrazedName: 'tachki',
      },
      {
        name: 'харлей',
        parametrazedName: 'kharliei',
      },
      {
        name: 'чоппер',
        parametrazedName: 'choppier',
      },
      {
        name: 'bike',
        parametrazedName: 'bike',
      },
      {
        name: 'caferacer',
        parametrazedName: 'caferacer',
      },
      {
        name: 'camaro',
        parametrazedName: 'camaro',
      },
      {
        name: 'chevrolet',
        parametrazedName: 'chevrolet',
      },
      {
        name: 'chopper',
        parametrazedName: 'chopper',
      },
      {
        name: 'dotheton',
        parametrazedName: 'dotheton',
      },
      {
        name: 'honda',
        parametrazedName: 'honda',
      },
      {
        name: 'jaguar',
        parametrazedName: 'jaguar',
      },
      {
        name: 'jdm',
        parametrazedName: 'jdm',
      },
      {
        name: 'land rover',
        parametrazedName: 'land-rover',
      },
      {
        name: 'lexus',
        parametrazedName: 'lexus',
      },
      {
        name: 'mercedes',
        parametrazedName: 'mercedes',
      },
      {
        name: 'range rover',
        parametrazedName: 'range-rover',
      },
      {
        name: 'vw',
        parametrazedName: 'vw',
      },
      {
        name: 'yamaha',
        parametrazedName: 'yamaha',
      },
    ],
  },
  {
    position: 65,
    color: '',
    name: 'Череп и кости',
    parametrazedName: 'cherep-i-kosti',
    children: [
      {
        name: 'пират',
        parametrazedName: 'pirat',
      },
      {
        name: 'скелет',
        parametrazedName: 'skieliet',
      },
      {
        name: 'тату',
        parametrazedName: 'tatu',
      },
      {
        name: 'череп',
        parametrazedName: 'chieriep',
      },
      {
        name: 'черепа',
        parametrazedName: 'chieriepa',
      },
      {
        name: 'skull',
        parametrazedName: 'skull',
      },
    ],
  },
  {
    position: 80,
    color: null,
    name: 'Праздники и события',
    parametrazedName: 'podarki-na-prazdniki',
    children: [
      {
        name: 'дед мороз',
        parametrazedName: 'died-moroz',
      },
      {
        name: 'день защитника отечества',
        parametrazedName: 'dien-zashchitnika-otiechiestva',
      },
      {
        name: 'день победы',
        parametrazedName: 'dien-pobiedy',
      },
      {
        name: 'день рождения',
        parametrazedName: 'dien-rozhdieniia',
      },
      {
        name: 'день святого патрика',
        parametrazedName: 'dien-sviatogho-patrika',
      },
      {
        name: 'ко дню влюбленных',
        parametrazedName: 'ko-dniu-vliubliennykh',
      },
      {
        name: 'новый год',
        parametrazedName: 'novyi-ghod',
      },
      {
        name: 'победа',
        parametrazedName: 'pobieda',
      },
      {
        name: 'подарок',
        parametrazedName: 'podarok',
      },
      {
        name: 'праздник',
        parametrazedName: 'prazdnik',
      },
      {
        name: 'рождество',
        parametrazedName: 'rozhdiestvo',
      },
      {
        name: 'санта',
        parametrazedName: 'santa',
      },
      {
        name: 'свадьба',
        parametrazedName: 'svadba',
      },
      {
        name: 'хэллоуин',
        parametrazedName: 'khellouin',
      },
      {
        name: '14 февраля',
        parametrazedName: '14-fievralia',
      },
      {
        name: '23 февраля',
        parametrazedName: '23-fievralia',
      },
      {
        name: '8 марта',
        parametrazedName: '8-marta',
      },
      {
        name: '9 мая',
        parametrazedName: '9-maia',
      },
      {
        name: 'christmas',
        parametrazedName: 'christmas',
      },
      {
        name: "valentine's day",
        parametrazedName: 'valentines-day',
      },
    ],
  },
  {
    position: 90,
    icon: {
      style: 'fas fa-heart',
      color: '#b730ff',
    },
    color: '#b730ff',
    name: '8 марта',
    parametrazedName: '8-marta',
    children: [],
  },
  {
    position: 92,
    color: null,
    name: 'Еда и напитки',
    parametrazedName: 'Eda-i-napitki',
    children: [
      {
        name: 'аленка',
        parametrazedName: 'alienka',
      },
      {
        name: 'алкоголь',
        parametrazedName: 'alkoghol',
      },
      {
        name: 'ананас',
        parametrazedName: 'ananas',
      },
      {
        name: 'арбуз',
        parametrazedName: 'arbuz',
      },
      {
        name: 'бананы',
        parametrazedName: 'banany',
      },
      {
        name: 'бургер',
        parametrazedName: 'burghier',
      },
      {
        name: 'вино',
        parametrazedName: 'vino',
      },
      {
        name: 'виски',
        parametrazedName: 'viski',
      },
      {
        name: 'еда',
        parametrazedName: 'ieda',
      },
      {
        name: 'кола',
        parametrazedName: 'kola',
      },
      {
        name: 'контрольная закупка',
        parametrazedName: 'kontrolnaia-zakupka',
      },
      {
        name: 'кофе',
        parametrazedName: 'kofie',
      },
      {
        name: 'молоко',
        parametrazedName: 'moloko',
      },
      {
        name: 'мороженое',
        parametrazedName: 'morozhienoie',
      },
      {
        name: 'пиво',
        parametrazedName: 'pivo',
      },
      {
        name: 'пицца',
        parametrazedName: 'pitstsa',
      },
      {
        name: 'пончик',
        parametrazedName: 'ponchik',
      },
      {
        name: 'сладости',
        parametrazedName: 'sladosti',
      },
      {
        name: 'фрукты',
        parametrazedName: 'frukty',
      },
      {
        name: 'чай',
        parametrazedName: 'chai',
      },
      {
        name: 'чашка',
        parametrazedName: 'chashka',
      },
      {
        name: 'шоколад',
        parametrazedName: 'shokolad',
      },
      {
        name: 'ягоды',
        parametrazedName: 'iaghody',
      },
      {
        name: 'alcohol',
        parametrazedName: 'alcohol',
      },
      {
        name: 'chocolate',
        parametrazedName: 'chocolate',
      },
      {
        name: 'donuts',
        parametrazedName: 'donuts',
      },
      {
        name: 'ice cream',
        parametrazedName: 'ice-cream',
      },
      {
        name: 'vegan',
        parametrazedName: 'vegan',
      },
    ],
  },
  {
    position: 99,
    icon: {
      style: 'fas fa-graduation-cap',
      color: '#0713ff',
    },
    color: '#0713ff',
    name: 'выпускной',
    parametrazedName: 'vypusknoi',
    children: [],
  },
  {
    position: 100,
    color: null,
    name: 'Знаменитости',
    parametrazedName: 'printy-znamenitosti',
    children: [
      {
        name: 'брюс ли',
        parametrazedName: 'brius-li',
      },
      {
        name: 'виктор цой',
        parametrazedName: 'viktor-tsoi',
      },
      {
        name: 'гагарин',
        parametrazedName: 'ghagharin',
      },
      {
        name: 'есенин',
        parametrazedName: 'iesienin',
      },
      {
        name: 'ким чен ын',
        parametrazedName: 'kim-chien-yn',
      },
      {
        name: 'конор макгрегор',
        parametrazedName: 'konor-makghrieghor',
      },
      {
        name: 'ленин',
        parametrazedName: 'lienin',
      },
      {
        name: 'лукашенко',
        parametrazedName: 'lukashienko',
      },
      {
        name: 'маск',
        parametrazedName: 'mask-1',
      },
      {
        name: 'маяковский',
        parametrazedName: 'maiakovskii',
      },
      {
        name: 'мэрилин монро',
        parametrazedName: 'merilin-monro',
      },
      {
        name: 'обама',
        parametrazedName: 'obama',
      },
      {
        name: 'овечкин',
        parametrazedName: 'oviechkin',
      },
      {
        name: 'сальвадор дали',
        parametrazedName: 'salvador-dali',
      },
      {
        name: 'сталин',
        parametrazedName: 'stalin',
      },
      {
        name: 'том харди',
        parametrazedName: 'tom-khardi',
      },
      {
        name: 'че гевара',
        parametrazedName: 'chie-ghievara',
      },
      {
        name: 'шварценеггер',
        parametrazedName: 'shvartsienieghghier',
      },
      {
        name: 'шнуров',
        parametrazedName: 'shnurov',
      },
      {
        name: 'эскобар',
        parametrazedName: 'eskobar',
      },
      {
        name: 'che guevara',
        parametrazedName: 'che-guevara',
      },
      {
        name: 'marilyn monroe',
        parametrazedName: 'marilyn-monroe',
      },
    ],
  },
  {
    position: 105,
    color: null,
    name: 'Мемы',
    parametrazedName: 'printy-memy',
    children: [
      {
        name: 'гарольд',
        parametrazedName: 'gharold',
      },
      {
        name: 'грустный кот',
        parametrazedName: 'ghrustnyi-kot',
      },
      {
        name: 'ждун',
        parametrazedName: 'zhdun',
      },
      {
        name: 'мем',
        parametrazedName: 'miem',
      },
      {
        name: 'пепе',
        parametrazedName: 'piepie',
      },
      {
        name: 'сиба',
        parametrazedName: 'siba',
      },
      {
        name: 'сиба-ину',
        parametrazedName: 'siba-inu',
      },
      {
        name: 'цифровое сопротивление',
        parametrazedName: 'tsifrovoie-soprotivlieniie',
      },
      {
        name: 'это фиаско братан',
        parametrazedName: 'eto-fiasko-bratan',
      },
      {
        name: 'digital resistance',
        parametrazedName: 'digital-resistance',
      },
      {
        name: 'grumpy',
        parametrazedName: 'grumpy',
      },
      {
        name: 'mem',
        parametrazedName: 'mem-1',
      },
      {
        name: 'meme',
        parametrazedName: 'meme',
      },
      {
        name: 'pepe',
        parametrazedName: 'pepe',
      },
      {
        name: 'pepe frog',
        parametrazedName: 'pepe-frog',
      },
    ],
  },
  {
    position: 110,
    color: null,
    name: 'Парные',
    parametrazedName: 'parnyе',
    children: [
      {
        name: 'для влюбленных',
        parametrazedName: 'dlia-vliubliennykh',
      },
      {
        name: 'надписи для двоих',
        parametrazedName: 'nadpisi-dlia-dvoikh',
      },
      {
        name: 'парные',
        parametrazedName: 'parnyie',
      },
    ],
  },
  {
    position: 120,
    color: null,
    name: 'Города и страны',
    parametrazedName: 'goroda-i-strany',
    children: [
      {
        name: 'беларусь',
        parametrazedName: 'bielarus',
      },
      {
        name: 'германия',
        parametrazedName: 'ghiermaniia',
      },
      {
        name: 'испания',
        parametrazedName: 'ispaniia',
      },
      {
        name: 'италия',
        parametrazedName: 'italiia',
      },
      {
        name: 'крым',
        parametrazedName: 'krym',
      },
      {
        name: 'лондон',
        parametrazedName: 'london-1',
      },
      {
        name: 'москва',
        parametrazedName: 'moskva',
      },
      {
        name: 'париж',
        parametrazedName: 'parizh',
      },
      {
        name: 'питер',
        parametrazedName: 'pitier',
      },
      {
        name: 'санкт-петербург',
        parametrazedName: 'sankt-pietierburgh',
      },
      {
        name: 'столица',
        parametrazedName: 'stolitsa',
      },
      {
        name: 'франция',
        parametrazedName: 'frantsiia',
      },
      {
        name: 'france',
        parametrazedName: 'france',
      },
      {
        name: 'london',
        parametrazedName: 'london',
      },
      {
        name: 'nyc',
        parametrazedName: 'nyc',
      },
    ],
  },
  {
    position: 121,
    icon: {
      style: 'fas fa-star',
      color: '#ff030b',
    },
    color: '#ff030b',
    name: 'День победы',
    parametrazedName: 'den-pobedu',
    children: [
      {
        name: 'великая отечественная война',
        parametrazedName: 'vielikaia-otiechiestviennaia-voina',
      },
      {
        name: 'день победы',
        parametrazedName: 'dien-pobiedy',
      },
      {
        name: '9 мая',
        parametrazedName: '9-maia',
      },
    ],
  },
  {
    position: 122,
    color: '#0091ff',
    name: 'бессмертный полк',
    parametrazedName: 'biessmiertnyi-polk',
    children: [],
  },
  {
    position: 150,
    icon: {
      style: 'fas fa-futbol',
      color: '#00000a',
    },
    color: '',
    name: 'Футбол',
    parametrazedName: 'printy-s-futbolom',
    children: [
      {
        name: 'аргентина',
        parametrazedName: 'arghientina',
      },
      {
        name: 'арсенал',
        parametrazedName: 'arsienal',
      },
      {
        name: 'бавария мюнхен',
        parametrazedName: 'bavariia-miunkhien',
      },
      {
        name: 'барселона',
        parametrazedName: 'barsielona',
      },
      {
        name: 'боруссия',
        parametrazedName: 'borussiia',
      },
      {
        name: 'динамо',
        parametrazedName: 'dinamo',
      },
      {
        name: 'ливерпуль',
        parametrazedName: 'livierpul',
      },
      {
        name: 'манчестер сити',
        parametrazedName: 'manchiestier-siti',
      },
      {
        name: 'манчестер юнайтед',
        parametrazedName: 'manchiestier-iunaitied',
      },
      {
        name: 'месси',
        parametrazedName: 'miessi',
      },
      {
        name: 'неймар',
        parametrazedName: 'nieimar',
      },
      {
        name: 'рубин',
        parametrazedName: 'rubin',
      },
      {
        name: 'терек',
        parametrazedName: 'tieriek',
      },
      {
        name: 'футбол 2018',
        parametrazedName: 'futbol-2018',
      },
      {
        name: 'футбольный мяч',
        parametrazedName: 'futbolnyi-miach',
      },
      {
        name: 'челси',
        parametrazedName: 'chielsi',
      },
      {
        name: 'barcelona',
        parametrazedName: 'barcelona',
      },
      {
        name: 'brazil',
        parametrazedName: 'brazil',
      },
      {
        name: 'juventus',
        parametrazedName: 'juventus',
      },
      {
        name: 'milan',
        parametrazedName: 'milan-1',
      },
      {
        name: 'real madrid',
        parametrazedName: 'real-madrid',
      },
    ],
  },
]
