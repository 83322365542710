"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../../constants");
var initialState = {
    rootPath: '',
    department: '',
    category: '',
    type: '',
    ownDesignPath: '',
    tag: '',
    subTag: '',
    page: 1,
    search: '',
    sort: 'admin_rating',
    price: { min: null, max: null },
    loading: false,
    loaded: true,
};
var reducer = function (filters, action) {
    if (filters === void 0) { filters = initialState; }
    var type = action.type, response = action.response;
    switch (type) {
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.START:
            return __assign(__assign({}, filters), { loading: true, loaded: false });
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return __assign(__assign({}, response.filters), { loading: false, loaded: true });
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.FAIL:
            return __assign(__assign({}, filters), { loading: false, loaded: false });
    }
    return __assign(__assign({}, filters), { loading: false, loaded: true });
};
exports.default = reducer;
