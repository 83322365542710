"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../../constants");
var initialState = {
    adminTagNames: '',
    afterMenuText: '',
    bannerImageUrl: '',
    beforeFooterText: '',
    h1Text: '',
    h2Text: '',
    htmlTitle: '',
    regularText: '',
    faq: [],
    breadcrumbs: [],
    advantages: [],
    tabsBlock: [],
};
exports.default = (function (seo, action) {
    if (seo === void 0) { seo = initialState; }
    var type = action.type, response = action.response;
    switch (type) {
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return response.seo || seo;
    }
    return seo;
});
