"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAdminSelector = exports.moduleName = void 0;
var constants_1 = require("../../../constants");
/**
 * Constants
 * */
exports.moduleName = 'isAdmin';
/**
 * Reducer
 * */
function reducer(state, action) {
    if (state === void 0) { state = false; }
    var type = action.type, response = action.response;
    switch (type) {
        case constants_1.ITEM + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return response.isAdmin;
        default:
            return state;
    }
}
exports.default = reducer;
/**
 * Selectors
 * */
var isAdminSelector = function (state) { return state[exports.moduleName]; };
exports.isAdminSelector = isAdminSelector;
/**
 * Action Creators
 * */
