"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../../constants");
var reducer = function (path, action) {
    if (path === void 0) { path = ''; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case constants_1.CHANGE_LOCATION:
            return payload.location.pathname;
    }
    return path;
};
exports.default = reducer;
