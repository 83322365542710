"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../../constants");
exports.default = (function (tags, action) {
    if (tags === void 0) { tags = []; }
    var type = action.type, response = action.response;
    switch (type) {
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return response.tags || tags;
    }
    return tags;
});
