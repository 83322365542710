import React from 'react'
import { Provider } from 'react-redux'
import createStoreForSSR from '../StoreApp/store'
import Router from '../common/Router'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Loader from '../common/Loader'
import tags from '../../mocks/tags'
import { theme } from '../styles/theme'

const paths = {
  store: { path: ['/store*', '/profile/favorites'], exact: false },
  item: { path: '/:itemType/:id(\\d+)', exact: true },
}

const customStorePaths = {
  store: { path: '/', exact: false },
}

/** @param {import('./StoreApp').StoreAppProps} props */
export default function App(props) {
  const components = props.components
  const state = Object.assign({}, props.state)
  const path = state.path

  if (state.categories) {
    state.categories = JSON.parse(state.categories)
  }

  if (process.env.NODE_ENV === 'development') {
    state.tags = tags
  }

  const store = createStoreForSSR(state)

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router path={path}>
          <Switch>
            <Route exact={paths.store.exact} path={paths.store.path}>
              <components.StoreRoot fallback={<Loader />} />
            </Route>
            <Route exact={paths.item.exact} path={paths.item.path}>
              <components.ItemRoot fallback={<Loader />} />
            </Route>
            <If condition={state.isCustomStore}>
              <Route
                exact={customStorePaths.store.exact}
                path={customStorePaths.store.path}
              >
                <components.StoreRoot fallback={<Loader />} />
              </Route>
            </If>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}
