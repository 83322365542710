"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.licenseeStoreSelector = exports.moduleName = void 0;
var constants_1 = require("../../../constants");
/**
 * Constants
 * */
exports.moduleName = 'ga4';
/**
 * Reducer
 * */
var initialState = {
    itemBrand: '',
    itemCategory: '',
    itemCategory2: '',
    itemCategory3: '',
    itemId: 0,
    itemName: '',
    itemVariant: '',
    item_id: 0,
    price: 0,
    quantity: 0
};
function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, response = action.response;
    switch (type) {
        case constants_1.ITEM + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return response.ga4;
        default:
            return state;
    }
}
exports.default = reducer;
/**
 * Selectors
 * */
var licenseeStoreSelector = function (state) { return state[exports.moduleName]; };
exports.licenseeStoreSelector = licenseeStoreSelector;
/**
 * Action Creators
 * */
