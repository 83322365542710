"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reviewsSelector = exports.moduleName = void 0;
var constants_1 = require("../../../constants");
/**
 * Constants
 * */
exports.moduleName = 'reviews';
/**
 * Reducer
 * */
var initialState = {
    id: 0,
    comments: '',
    author: '',
    content: [],
    counts: 0,
    path: '',
};
function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, response = action.response;
    switch (type) {
        case constants_1.ITEM + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return response.reviews;
        default:
            return state;
    }
}
exports.default = reducer;
/**
 * Selectors
 * */
var reviewsSelector = function (state) { return state[exports.moduleName]; };
exports.reviewsSelector = reviewsSelector;
/**
 * Action Creators
 * */
