"use strict";
// @ts-nocheck
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Node = /** @class */ (function () {
    function Node(data, parent, children) {
        if (data === void 0) { data = {}; }
        if (parent === void 0) { parent = null; }
        if (children === void 0) { children = []; }
        this.data = data;
        this.parent = parent;
        this.children = children;
    }
    return Node;
}());
var Tree = /** @class */ (function () {
    function Tree(array) {
        this.root = new Node({});
        this.createByArray(array);
    }
    Tree.prototype.createByArray = function (array, parent, data) {
        var _this = this;
        if (parent === void 0) { parent = null; }
        if (data === void 0) { data = ['title', 'url']; }
        if (!parent)
            parent = this.root;
        var _loop_1 = function (i) {
            var el = array[i];
            if (data.length) {
                el.data = data.reduce(function (acc, item) {
                    var _a;
                    if (item === "url") {
                        el[item] = _this._changeRootPath(el[item]);
                    }
                    return __assign(__assign({}, acc), (_a = {}, _a[item] = el[item], _a));
                }, {});
            }
            var node = new Node(el.data, parent, []);
            parent.children.push(node);
            if (el.children.length) {
                this_1.createByArray(el.children, node);
            }
            return "continue";
        };
        var this_1 = this;
        for (var i = 0; i < array.length; i++) {
            _loop_1(i);
        }
        return this;
    };
    Tree.prototype.createByValue = function (value) {
        var nodeOfCategory = this.findNodeByValue(value) || this.root;
        var parents = this.getParents(nodeOfCategory).reverse();
        return __spreadArray(__spreadArray([], parents, true), [nodeOfCategory], false);
    };
    Tree.prototype.findNodeByValue = function (value, key) {
        if (key === void 0) { key = 'url'; }
        var result;
        var arrayOfValues = value.split("/");
        var _loop_2 = function (j) {
            var val = arrayOfValues[j];
            var findNodeInArray = function (array) {
                for (var i = 0; i < array.length; i++) {
                    if (result) {
                        return result;
                    }
                    var node = array[i];
                    if (val === node.data[key].split("/").pop()) {
                        result = node;
                        return node;
                    }
                    else {
                        findNodeInArray(node.children);
                    }
                }
                return result;
            };
            findNodeInArray(this_2.root.children);
        };
        var this_2 = this;
        for (var j = arrayOfValues.length - 1; j >= 0; j--) {
            _loop_2(j);
        }
        return result;
    };
    Tree.prototype.getParents = function (node) {
        var result = [];
        var getParent = function (n) {
            if (n && n.parent) {
                result.push(n.parent);
                getParent(n.parent);
            }
            else {
                return result;
            }
            return result;
        };
        return getParent(node);
    };
    Tree.prototype.simpleFindNodeByValue = function (value, rootPath, node, key) {
        if (key === void 0) { key = "url"; }
        var array = node.children;
        var val = value.replace(rootPath, "");
        var result;
        if (array) {
            for (var i = 0; i < array.length; i++) {
                var nod = array[i];
                if (val === nod.data[key]) {
                    result = nod;
                    return nod;
                }
            }
        }
        return result;
    };
    Tree.prototype.updateByValue = function (value, rootPath, tree) {
        var children = this.simpleFindNodeByValue(value, rootPath, tree[tree.length - 1]);
        var parent = this.simpleFindNodeByValue(value, rootPath, tree[tree.length - 2]);
        if (children) {
            return __spreadArray(__spreadArray([], tree, true), [children], false);
        }
        else if (parent) {
            return __spreadArray(__spreadArray([], tree.slice(0, -1), true), [parent], false);
        }
        return this.createByValue(value);
    };
    Tree.prototype.transformToFlatList = function (tree) {
        return tree.map(function (node) {
            if (node.parent) {
                node.parent.children.forEach(function (item) {
                    item.data.active = false;
                });
            }
            node.data.active = true;
            return node.children.map(function (child) {
                child.data.active = false;
                return child.data;
            });
        });
    };
    Tree.prototype._changeRootPath = function (path) {
        var regExp = new RegExp("(/store(?=/))|(/store(?=$))");
        if (regExp.test(path)) {
            return path.replace(/^\/store/, "");
        }
        return path;
    };
    return Tree;
}());
exports.default = Tree;
