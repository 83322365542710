"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../../constants");
var constants_2 = require("../constants");
var initialState = {
    items: [],
    loading: false,
    loaded: true,
    error: '',
    fromFavoritesUpdate: false,
};
exports.default = (function (products, action) {
    if (products === void 0) { products = initialState; }
    var type = action.type, payload = action.payload, response = action.response, error = action.error;
    switch (type) {
        case constants_2.UPDATE_PRODUCTS:
            return {
                items: payload.items,
                loading: false,
                loaded: true,
                fromFavoritesUpdate: true,
                error: error,
            };
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.START:
            return __assign(__assign({}, products), { loading: true, loaded: false, error: error });
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return payload.addProducts
                ? {
                    items: __spreadArray(__spreadArray([], products.items, true), response.products.items, true),
                    loading: false,
                    loaded: true,
                    error: error,
                }
                : {
                    items: response.products.items,
                    loading: false,
                    loaded: true,
                    error: error,
                };
        case constants_1.STORE + constants_1.LOAD_DATA + constants_1.FAIL:
            return __assign(__assign({}, products), { loading: false, loaded: false, error: error });
        case constants_2.SAVE_ADMIN_SETUP + constants_1.START:
            return __assign(__assign({}, products), { loading: true, loaded: false, error: error });
        case constants_2.SAVE_ADMIN_SETUP + constants_1.SUCCESS:
            return (function () {
                var items = products.items.map(function (i) {
                    var _a;
                    if (((_a = i.admin) === null || _a === void 0 ? void 0 : _a.action) === response.action) {
                        return __assign(__assign({}, i), { admin: __assign(__assign({}, i.admin), response) });
                    }
                    return i;
                });
                return {
                    items: items,
                    loading: false,
                    loaded: true,
                    error: error,
                };
            })();
        case constants_2.SAVE_ADMIN_SETUP + constants_1.FAIL:
            return __assign(__assign({}, products), { loading: false, loaded: false, error: error });
    }
    return products;
});
