"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.locationDataSelector = exports.moduleName = void 0;
var constants_1 = require("../../../constants");
/**
 * Constants
 * */
exports.moduleName = 'locationData';
/**
 * Reducer
 * */
var initialState = {
    current: {
        fiasId: '',
        fullName: '',
        name: ''
    },
    majorCities: {}
};
function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, response = action.response;
    switch (type) {
        case constants_1.ITEM + constants_1.LOAD_DATA + constants_1.SUCCESS:
            return response.locationData;
        default:
            return state;
    }
}
exports.default = reducer;
/**
 * Selectors
 * */
var locationDataSelector = function (state) { return state[exports.moduleName]; };
exports.locationDataSelector = locationDataSelector;
/**
 * Action Creators
 * */
