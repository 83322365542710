export default (filters, options) => {
  let { tag, subTag, combine, sort, search, page, price } = filters
  let path = ''
  let {
    tag: replTag,
    subTag: replSubTag,
    combine: replCombine,
    sort: replSort,
    page: replPage,
  } = options

  if (replCombine || replCombine === '') {
    combine = replCombine
  }

  if (replTag || replTag === '') {
    tag = replTag
  }

  if (replSubTag || replSubTag === '') {
    subTag = replSubTag
  }

  if (replSort) {
    sort = replSort
  }

  if (replPage) {
    page = replPage
  }

  if (combine) {
    path += /^\//g.test(combine) ? combine : `/${combine}`
  }

  if (tag) {
    path += `/${tag}`

    if (subTag) {
      path += `/${subTag}`
    }
  }

  if (page && +page !== 1) {
    path += `/page-${page}`
  }

  const hasPrice = price && (price.min || price.max)
  const hasSort = sort && sort !== 'admin_rating'

  if (search || hasSort || hasPrice) {
    path += '?'
    const query = []

    if (search) {
      query.push(`q=${search}`)
    }

    if (hasSort) {
      query.push(`sort_by=${sort}`)
    }

    if (hasPrice) {
      const { min, max } = price

      if (min) {
        query.push(`min_price=${min}`)
      }

      if (max) {
        query.push(`max_price=${max}`)
      }
    }

    path += query.join('&')
  }

  if (path.length && path[0] !== '/') path = '/' + path

  return path
}
