export const setGA4Product = (productId) => {
  const ga4Products = JSON.parse(localStorage.getItem('ga4Products') || '[]')

  ga4Products.push({
    item_id: productId,
    item_list_name: window.location.pathname,
  })

  localStorage.setItem('ga4Products', JSON.stringify(ga4Products))
}

export const getGA4Product = (productId) => {
  const ga4Products = JSON.parse(localStorage.getItem('ga4Products') || '[]')

  return (
    ga4Products.find((product) => product.item_id == productId) || {
      item_list_name: '',
    }
  )
}
